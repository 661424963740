<template>
  <v-data-table
    v-model:items-per-page="options.itemsPerPage"
    v-model:items-per-page-options="options.itemsPerPageOptions"
    fixed-header
    :items="items"
    :headers="headers"
    :height="$vuetify.display.height - 64"
  >
    <template #bottom></template>
    <template #headers="{ columns: tableHeaders }">
      <tr>
        <template
          v-for="(header, key) in tableHeaders"
          :key="key"
        >
          <th :width="header.width">
            <div
              class="text-truncate"
              :style="`max-width: ${header.width}px; min-width: ${header.width}px;`"
            >
              {{ header.title }}<br />
              <v-chip
                v-if="header.external"
                size="small"
                class="mb-2"
              >
                Außenrotand
              </v-chip>
            </div>
          </th>
        </template>
      </tr>
    </template>
    <template #body="{ items: bodyItems, columns: bodyHeaders }">
      <template
        v-for="item in bodyItems"
        :key="item.id"
      >
        <tr>
          <template
            v-for="header in bodyHeaders"
            :key="header.key"
          >
            <td v-if="item[header.key] && header.key === 'name'">
              <div
                class="text-truncate"
                :style="`max-width: ${header.width}px; min-width: ${header.width}px;`"
              >
                {{ item.name }}<br />
                <span
                  v-if="item.min || item.max"
                  class="text-subtitle-2 text-grey-darken-2"
                >
                  Min: {{ item.min }} - Max: {{ item.max }}
                </span>
                <br />
                <v-chip
                  v-if="item.external"
                  size="small"
                  class="mb-2"
                  color="grey-darken-2"
                >
                  Rotation
                </v-chip>
              </div>
            </td>
            <td
              v-else-if="item[header.key]"
              width="100"
              :class="{ 'bg-primary': item[header.key].selected }"
            >
              <v-tooltip
                location="right"
                text="Tooltip"
                lazy
              >
                <template #activator="{ props }">
                  <v-progress-linear
                    :model-value="item[header.key].totalPoints"
                    :max="points.maxPoints"
                    :color="
                      getColorForPercentage(item[header.key].totalPoints, points.maxPoints) +
                      '-darken-2'
                    "
                    height="15"
                    rounded
                    v-bind="props"
                  ></v-progress-linear>
                </template>
                Punkte: {{ item[header.key].totalPoints }}
                <ul class="ml-4">
                  <li
                    v-for="reason in Object.keys(item[header.key].reasons)"
                    :key="reason + item.id"
                  >
                    {{ getReasonTitle(reason) }}:
                    {{ Math.round(item[header.key].reasons[reason] * 100) / 100 }}
                  </li>
                </ul>
              </v-tooltip>
            </td>
            <td v-else>
              <v-progress-linear
                :model-value="0"
                :max="points.maxPoints"
                height="15"
                rounded
              ></v-progress-linear>
            </td>
          </template>
        </tr>
      </template>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    points: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    options: {
      itemsPerPage: 50,
      itemsPerPageOptions: [
        { title: '50', value: 50 },
        { title: '100', value: 100 },
        { title: '200', value: 200 },
      ],
    },
  }),
  computed: {
    items() {
      if (this.points) {
        const items = []
        if (this.points) {
          items.push(
            ...this.points.units.map((unit) => {
              return {
                ...this.points.pointMatrix[unit.id],
                id: unit.id,
                name: unit.short_name,
                min: unit.minimum_vacancies,
                max: unit.maximum_vacancies,
              }
            })
          )
          if (this.points.flow) {
            items.push(
              ...this.points.flow.nodes
                .filter(
                  (node) =>
                    node.type === 'external' &&
                    node.facilities?.filter((directusFacility) => directusFacility.facility_id)
                      .length
                )
                .map((node) => {
                  return node.facilities
                    .filter((directusFacility) => directusFacility.facility_id)
                    .map((directusFacility) => {
                      const facility = directusFacility.facility_id
                      return {
                        ...this.points.pointMatrix[facility.id],
                        id: facility.id,
                        name: facility.name,
                        min: node.minimum_vacancies,
                        max: node.maximum_vacancies,
                      }
                    })
                })
                .flat()
            )
          }
        }
        return items.filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id)
        )
      } else {
        return []
      }
    },
    headers() {
      const headers = [{ key: 'name', width: 200 }]
      if (this.points) {
        headers.push(
          ...this.points.users.map((user) => {
            return {
              key: user.id,
              title: this.formatUserName(user),
              width: 100,
            }
          }),
          ...this.points.externalUsers.map((user) => {
            return {
              key: user.id,
              title: this.formatUserName(user),
              width: 100,
              external: true,
            }
          })
        )
      }
      return headers
    },
  },
}
</script>
<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background-color: #f8f8f8 !important;
}

table > thead > tr > th {
  z-index: 9999;
  background-color: #f8f8f8 !important;
}

table tr > td,
table tr > th {
  border-left: 1px solid #dddddd;
}
</style>
